<template>
  <v-container
    fluid
  >
    <v-row class="justify-center">
      <div class="text-h2 mb-1 grey--text text--darken-2 font-weight-medium">
        Add Equipment Or Model
      </div>
    </v-row>
    <v-row class="justify-center mt-6">
      <v-card
        min-width="600px"
        max-width="950px"
      >
        <div
          :class="`mt-10 mb-6 ${state0 === 'Equipment' ? 'wizard-equipment' : 'wizard-model'}`"
        >
          <div>
            What would you like to add?
          </div>
          <v-select
            id="ewcWizardInitialDropdown"
            v-model="state0"
            :items="['Equipment', 'Equipment Model']"
            dense
            @change="showAlert = false"
          />
        </div>
        <v-expand-transition v-if="state0 === 'Equipment'">
          <ewc-equipment-flow :local-bus="localBus" />
        </v-expand-transition>
        <v-sheet
          class="mt-8"
          width="100%"
          style="margin-right: 10%; margin-left: 10%"
        >
          <span />
        </v-sheet>
        <v-expand-transition v-if="state0 === 'Equipment Model'">
          <ewc-model-flow :local-bus="localBus" />
        </v-expand-transition>
      </v-card>
      <v-col
        cols="12"
        class="d-flex justify-center"
      >
        <v-alert
          v-if="showAlert"
          :width="state0 === 'Equipment' ? '950px' : '600px'"
          type="error"
        >
          {{ alertMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <material-snackbar
      v-model="showSnackBar"
      type="info"
      timeout="3000"
      class="ml-12"
      v-bind="{
        ['bottom']: true,
        ['left']: true
      }"
    >
      {{ snackBarMessage }}
    </material-snackbar>
  </v-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'EWCWizard',
    data: () => ({
      localBus: new Vue(),
      state0: '',

      showSnackBar: false,
      snackBarMessage: '',
      showAlert: false,
      alertMessage: '',

    }),
    created () {
      this.localBus.$on('showAlertMessage', message => {
        this.showAlert = true
        this.alertMessage = message
      })
      this.localBus.$on('removeAlertMessage', () => {
        this.showAlert = false
      })
      this.localBus.$on('addSuccess', message => {
        this.state0 = ''
        this.showSnackBar = true
        this.snackBarMessage = message
      })
    },
    methods: {
    },
  }
</script>
<style>
.wizard-equipment {
  width: 400px;
  margin-left: 48px;
}
.wizard-model {
  width: 80%;
  margin-right: 10%;
  margin-left: 10%;
}
</style>
